import variables from '@/assets/css/exports/variables.scss';

const markingsTypes = [
  {
    value: 'machine_time',
    text: 'Machine Time',
    highlight: 'pink',
    rgbColor: variables.colorPinkMagenta,
    cols: 2,
  },
  {
    value: 'trainings',
    text: 'Trainings',
    highlight: 'purple',
    rgbColor: variables.colorPurpleLight,
    cols: 2,
  },
  {
    value: 'blocked',
    text: 'Blocked',
    highlight: 'blue',
    rgbColor: variables.colorBlue,
    cols: 2,
  },
  {
    value: 'taken',
    text: 'Users` reservations',
    highlight: 'gray',
    rgbColor: variables.colorGrayLight,
    cols: 3,
  },
  {
    value: 'user',
    text: 'Your reservations<br/>(Select proposal first)',
    highlight: 'teal',
    rgbColor: variables.colorTeal,
    cols: 3,
  },
];

const markingsTypesWithoutUser = markingsTypes.filter((el) => !['user'].includes(el.value));

const userDashboardReservationsCalendarLayout = {
  default: { columns: 1, rows: 2, isExpanded: false },
  tablet: { columns: 2, rows: 2, isExpanded: false },
  laptop: { columns: 3, rows: 2, isExpanded: false },
  desktop_lg: { columns: 3, rows: 3, isExpanded: true },
  desktop: { columns: 2, rows: 3, isExpanded: true },
};

const managerDashboardReservationsCalendarLayout = {
  default: { columns: 1, rows: 2, isExpanded: true },
  tablet: { columns: 2, rows: 2, isExpanded: true },
  laptop: { columns: 3, rows: 2, isExpanded: true },
  desktop_lg: { columns: 3, rows: 2, isExpanded: true },
  desktop: { columns: 2, rows: 3, isExpanded: true },
};

const configCalendarLayout = {
  default: { columns: 2, rows: 1 },
  tablet: { columns: 2, rows: 1 },
  laptop: { columns: 2, rows: 1 },
  desktop_lg: { columns: 3, rows: 2 },
  desktop: { columns: 2, rows: 2 },
};

const getCalendarLayout = (layoutName) => {
  const layouts = {
    userDashboardReservations: userDashboardReservationsCalendarLayout,
    managerDashboardReservations: managerDashboardReservationsCalendarLayout,
    managerDashboardConfig: configCalendarLayout,
  };
  return layouts[layoutName];
};

export {
  markingsTypes,
  markingsTypesWithoutUser,
  userDashboardReservationsCalendarLayout,
  managerDashboardReservationsCalendarLayout,
  configCalendarLayout,
  getCalendarLayout,
};
