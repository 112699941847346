<template>
  <div>
    <b-tabs v-model="selected_tab"
            card>
      <b-tab active
             title="CryoEM reservations"
             @click="rerender_cryo_component">
        <b-card>
          <b-row>
            <b-col class="mb-3">
              <call-filter :id="id"
                           ref="call_filter"
                           v-model="proposals"
                           class="mb-10 mr-10 sm-10  xs-10"
                           @load_data="load_data_for"
                           @selected_call_change="handleCallChange"
                           @update_proposals="refresh_proposals_filter"/>
            </b-col>
          </b-row>

          <b-row id="reservation_form">
            <b-col>
              <cryoem_reservations_form :proposals="all_proposals_for_call"
                                        :render-calendar-trigger="renderCalendarTrigger"
                                        @get_proposals="rewrite_proposals"/>
            </b-col>
          </b-row>

          <b-row id="reservation_list">
            <b-col>
              <cryoem_reservations_list :fields="fields"
                                        :parent="id"
                                        :proposals="proposals"
                                        :selected_call="selected_call"
                                        @setup_proposal="check_proposals_len"
                                        @reservations-changed="rewriteReservations"/>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>

      <b-tab title="Exclude dates"
             @click="load_config">
        <b-card>
          <b-row id="exclude_form"
                 align-h="around"
                 style="min-height: 12em">
            <b-col cols="auto">
              <cryoem_dates_exclude_form :render-calendar-trigger="renderCalendarTrigger"
                                         @config_changed="rewrite_config"/>
            </b-col>
            <b-col>
              <cryoem_dates_exclude_list :config="config"
                                         :fields="exclude_fields"
                                         @config_changed="rewrite_config"/>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
  import callFilter from '@/components/call_filter';
  import cryoem_dates_exclude_form from '@/components/operational/cryoem/cryoem_dates_exclude_form';
  import cryoem_dates_exclude_list from '@/components/operational/cryoem/cryoem_dates_exclude_list';
  import cryoem_reservations_form from '@/components/operational/cryoem/cryoem_reservations_form';
  import cryoem_reservations_list from '@/components/operational/cryoem/cryoem_reservations_list';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'cryoem_reservations_dashboard',
    components: {
      callFilter,
      cryoem_dates_exclude_form,
      cryoem_dates_exclude_list,
      cryoem_reservations_form,
      cryoem_reservations_list,
    },
    data() {
      return {
        proposals: [],
        all_proposals: {},
        all_proposals_for_call: [],
        id: 1,
        actions_disabled: ['edit'],
        fields: [
          ordinalNumber,
          { key: 'document_specification.document_id', label: 'Id', sortable: true },
          { key: 'reservations.start', label: 'Start date', sortable: true, sorted: true },
          { key: 'reservations.end', label: 'End date', sortable: true },
          { key: 'title', sortable: true },
          { key: 'author', sortable: true },
          { key: 'final_grade.number_of_shifts', label: 'Granted shifts - committee', sortable: true },
          { key: 'management_evaluation.number_of_shifts', label: 'Granted shifts - management', sortable: true },
          { key: 'actions' },
        ],
        exclude_fields: [
          ordinalNumber,
          { key: 'start', label: 'Start date', sortable: true, editable: true, sorted: true },
          { key: 'end', label: 'End date', sortable: true, editable: true },
          { key: 'title', label: 'Title', sortable: true },
          { key: 'actions' },
        ],
        selected_call: '',
        config: {},
        proposals_len: null,
        selected_tab: 1,
        calls: {},
        // for force refreshing cryo calendar component
        renderCalendarTrigger: true,
      };
    },
    methods: {
      load_calls(call) {
        if (this.hasProperty(this.all_proposals, call)) {
          this.all_proposals_for_call = this.all_proposals[call];
        } else {
          this.load_all_cryoem_proposals(call);
        }
      },
      load_all_cryoem_proposals(call) {
        const query = {
          infrastructure: ['cryoem'],
          proposal_states: ['submitted'],
          subtypes: ['ceric'],
          call_name: this.calls[call].name,
        };
        this.axios.get('/documents', { params: query })
          .then((response) => {
            this.all_proposals[call] = response.data;
            this.all_proposals_for_call = this.all_proposals[call];
          });
      },
      async load_data_for(call) {
        this.calls = this.$store.getters['calls/calls_as_object']();
        this.loading = true;
        const url_param = { call, infrastructure: 'cryoem' };
        const response = await this.axios.get('/documents/reservations', { params: url_param });
        if (response.data) {
          this.proposals = response.data.documents;
          this.proposals_len = this.proposals.length;
          // setup the data in proposal-filter, call-filter component
          this.$emit('setup_proposal', this.proposals);
          // load data from children component
          this.$emit('load_data');
        }
        this.loading = false;
      },
      async load_config() {
        const response = await this.axios.get('/config/cryoem_calendar');
        this.config = response.data.config;
        await this.$store.dispatch('calendar/loadCalendarConfig', 'cryoem');
        this.rerender_cryo_component();
      },
      async rewriteReservations() {
        await this.$store.dispatch('calendar/loadCalendarReservations', 'cryoem');
        this.rerender_cryo_component();
      },
      check_proposals_len() {
        if (this.proposals.length < this.proposals_len) {
          this.load_config();
          this.proposals_len = this.proposals.length;
        }
      },
      handleCallChange(call) {
        this.call_change(call);
        this.rerender_cryo_component();
      },
      rerender_cryo_component() {
        this.renderCalendarTrigger = !this.renderCalendarTrigger;
      },
      async rewrite_config() {
        await this.load_config();
      },
      rewrite_proposals() {
        this.load_data_for(this.selected_call);
      },
    },
    watch: {
      proposals() {
        this.load_calls(this.selected_call);
      },
    },
    beforeCreate() {
      this.$store.dispatch('calendar/loadCalendarConfig', 'cryoem');
      this.$store.dispatch('calendar/loadCalendarReservations', 'cryoem');
    },
  };
</script>

<style lang="scss"
       scoped>

</style>
